/* eslint-disable */
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Segment } from '../../../core/segment/segments';
import { GlobalState } from '../../store/global';
import { selectActivationProcessBySegment } from '../../store/activationProcessSlice';
import SegmentEditorHeader from './Header';
import DefinitionStage from './DefinitionStage';
import MatchingModal from './MatchingModal';
import UJContext, { SegmentStage, SmartSegmentStage } from './UJContext';
import { dataBricksMatching } from '../../store/thunk/amdm/dataBricksMatching';
import '../../Common/BreadCrumb.less';
import { config } from '../../../core/config';
import MatchingStage from './MatchingStage';
import { getActivateStudioState } from '../../store/thunk/amdm/getActivateStudioState';
import { Spin } from 'antd';
import { EyeOutlined, SaveOutlined, LoadingOutlined } from '@ant-design/icons';
import SmartBuilder from '../../Common/SmartBuilder/SmartBuilder';
import segmentSlice from '../../store/segmentSlice';
import { useDataBricks } from '../../modules/common/hooks/useDataBricks';
import { objToBase64 } from '../../../core/helpers/tobase64';
import { ResponseInfos, VariableInfos } from 'hawaii';
import { updateSegmentPopulationThunk } from '../../store/thunk/updateSegmentPopulation';
import { countryMetadatas } from 'hawaii';
import { transformLabels } from '../../Common/SmartBuilder/Components/subAudienceCard';
import Warning from '../../Common/warning';

interface Props {
  segment: Segment;
  urlBack: string;
  selectedStage: SegmentStage | SmartSegmentStage;
  setSelectedStage: React.Dispatch<React.SetStateAction<SegmentStage | SmartSegmentStage>>;
}

const SegmentEditor: FC<Props> = ({ segment, urlBack, selectedStage, setSelectedStage }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { startSmartAudienceBuild, getSmartBuilderOutput } = useDataBricks(segment.id, 'smartBuilder');
  const [subAudiences, setSubAudiences] = useState<object[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [warningOpen, setWarningOpen] = useState<boolean>(false);
  const [runFailed, setRunFailed] = useState<boolean>(false);

  const sentimentDetect = (variable: string) => {
    if (segment.smartBuilder?.sentiments) {
      if (segment.smartBuilder.sentiments.positive.includes(variable)) {
        return 'positive';
      }
      if (segment.smartBuilder.sentiments.negative.includes(variable)) {
        return 'negative';
      }
      return 'not found';
    } else {
      return 'Smart builder not exists';
    }
  };

  useEffect(() => {
    if (
      segment?.smartBuilder?.recommanderStatus !== 'SUCCESS' ||
      segment.smartBuilder.audienceBuilderStatus === 'SUCCESS' ||
      !segment.smartBuilder.audienceBuilderStatus
    )
      return;

    const startBuildAudiences = async () => {
      const runIds = await Promise.all(
        subAudiences.map(async (audience) => {
          const objKey = Object.keys(audience)[0];
          const toBase64 = objToBase64(audience);
          const sentiment = sentimentDetect(objKey);
          const getCountryId = countryMetadatas.find((country) => country.iso === segment.countryIsos[0]);

          const notebook_params = {
            COUNTRY_ID: getCountryId?.id || 7,
            SENTIMENT: sentiment,
            SUB_SEGMENT: objKey,
            SELECTED_QUESTIONS: toBase64,
          };

          return await startSmartAudienceBuild(notebook_params);
        }),
      );

      dispatch(
        segmentSlice.actions.setSmartAudienceBuilderRunIds({
          id: segment.id,
          runIds: runIds,
        }),
      );
    };

    const getOutputAudienceBuilder = async () => {
      if (!segment.smartBuilder?.audienceBuilderRunIds) return;
      const output = await Promise.all(
        segment.smartBuilder?.audienceBuilderRunIds.map(async (runId) => {
          const response = await getSmartBuilderOutput(runId);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (response?.state.life_cycle_state === 'TERMINATED' && response.state.result_state !== 'FAILED') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            return { data: response.notebook, status: response.state.result_state };
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          else if (response?.state.life_cycle_state === 'TERMINATED' && response.state.result_state === 'FAILED') {
            dispatch(
              segmentSlice.actions.setSmartAudienceBuilderStatus({
                id: segment.id,
                audienceBuilderStatus: 'FAILED',
              }),
            );
            setLoading(false);
            setRunFailed(true);
          }
          return { data: '', status: 'FAILED' };
        }),
      );
      const allSuccess = output.every((data) => data?.status === 'SUCCESS');

      if (allSuccess) {
        output.map((data) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          const clearNone = data?.data.result.replace(/None/g, 'null');

          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          const replaceQuotes = clearNone.replace(/'(\w+)'(?=\s*:)/g, '"$1"').replace(/\\'/g, '');

          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          const fixQuotes = replaceQuotes.replace(/:\s*'([^']*)'/g, (_match: any, p1: string) => {
            const escapeVal = p1.replace(/'/g, "\\'").replace(/\"/g, '').replace(/null/g, 'None');
            return `: "${escapeVal}"`;
          });
          // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          // const correctedQuotes = fixQuotes.replace(/\\'/g, "'");
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
          const parseObj = JSON.parse(fixQuotes);

          interface TransformedData {
            selectedVariable: VariableInfos;
            selectedResponses: ResponseInfos[];
          }

          interface ResponseItem {
            parent_id: null | string;
            response_id: string;
            response_text: string;
            variable_id: string;
            variable_name: string;
            variable_text: string;
            step2_description: string;
          }

          const transformedVariables: TransformedData[] = Object.values(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            parseObj.reduce((list: Record<string, TransformedData>, item: ResponseItem) => {
              let key = item.parent_id ? item.parent_id : item.variable_id;

              if (key.startsWith('bix_')) {
                key = key.replace(/_\d+$/, '');
              }

              list[key] = list[key] || {
                selectedVariable: {
                  category_1: '',
                  category_2: '',
                  category_3: '',
                  isparent: item.parent_id ? 1 : 0,
                  variable_id: key,
                  variable_name: item.step2_description
                    ? transformLabels(item.step2_description).mainLabel !== item.variable_name && !item.parent_id
                      ? transformLabels(item.step2_description).mainLabel + ' - ' + item.variable_name || ''
                      : transformLabels(item.step2_description).mainLabel
                    : item.variable_name,
                  variable_text: item.variable_text,
                },
                selectedResponses: [],
              };

              if (
                list[key].selectedResponses.findIndex(
                  (el) => el.value === (item.parent_id ? item.variable_id : item.response_id),
                ) === -1
              ) {
                list[key].selectedResponses.push({
                  label: item.parent_id ? item.variable_name : item.response_text,
                  parentResponseId: item.parent_id ? '1' : '0',
                  value: item.parent_id ? item.variable_id : item.response_id,
                  variableId: key,
                });
              }

              return list;
            }, {}),
          );

          transformedVariables.map((variable: TransformedData) => {
            const selectedVariable = variable.selectedVariable;
            const selectedResponses = variable.selectedResponses;

            dispatch(
              segmentSlice.actions.segmentAddCriteria({
                id: segment.id,
                criteriaId: null,
                selectedVariable,
                selectedResponses,
                operator: 'AND',
              }),
            );
          });

          dispatch(updateSegmentPopulationThunk({ segmentId: segment.id }));

          dispatch(
            segmentSlice.actions.setSmartAudienceBuilderStatus({
              id: segment.id,
              audienceBuilderStatus: 'SUCCESS',
            }),
          );

          // eslint-disable-next-line
          dispatch(
            segmentSlice.actions.setSmartSavedSubAudiences({
              id: segment.id,
              subAudiences: segment.smartBuilder?.selectedSubAudiencies || [],
            }),
          );
          setSelectedStage('Definition');
          setLoading(false);
        });
      }
    };

    if (segment.smartBuilder?.audienceBuilderRunIds.length === 0) {
      void startBuildAudiences();
    }

    const interval = setInterval(() => {
      if (
        segment.smartBuilder?.audienceBuilderStatus === 'SUCCESS' ||
        segment.smartBuilder?.audienceBuilderStatus === 'FAILED'
      ) {
        clearInterval(interval);
      }

      if (segment.smartBuilder?.audienceBuilderRunIds.length === 0) {
        void startBuildAudiences();
      } else if (
        segment.smartBuilder?.audienceBuilderStatus !== 'SUCCESS' &&
        segment.smartBuilder?.audienceBuilderStatus !== 'FAILED'
      ) {
        void getOutputAudienceBuilder();
      }
    }, 5000);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return () => clearInterval(interval);
  }, [subAudiences, segment]);

  const handleBreadCrumbSelection = (value: SegmentStage | SmartSegmentStage) => {
    // if (
    //   value === 'Activation' &&
    //   (!activateStudioState.activateState?.data.length ||
    //     activateStudioState.activateState?.data.find(({ provider }) => provider === 'AUDIENCE_STUDIO')
    //       ?.statusActivateStudio !== 'PUSHED')
    // )
    //   return;
    if (value == 'Matching' && !activateStudioState.activateState) return;

    if (
      value === 'Matching' &&
      ((activateStudioState.activateState?.data.length &&
        activateStudioState.activateState?.data.find(
          ({ statusActivateStudio }) => statusActivateStudio === 'PUSHED',
        )) ||
        !segment.amdmInfos?.audienceRecordId)
    )
      return;

    if (
      segment.amdmInfos?.audienceRecordId &&
      activateStudioState.activateState?.data.length &&
      activateStudioState.activateState?.data.find(({ statusActivateStudio }) => statusActivateStudio === 'MATCHING')
    ) {
      dispatch(
        getActivateStudioState({
          audienceRecordId: segment.amdmInfos?.audienceRecordId,
          selectedCountry: segment.countryIsos[0],
        }),
      );
    }
    setCurrentStage(value);
    setSelectedStage(value);
  };

  const [enableExport, setEnableExport] = useState<boolean>(true);
  const [matchingModalOpen, setMatchingModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const activateStudioState = useSelector((state: GlobalState) => state.activateStudioState);

  const activationProcesses =
    segment !== undefined
      ? useSelector((state: GlobalState) => selectActivationProcessBySegment(state, segment.id))
      : [];

  const activationProcess = activationProcesses.filter(
    (ap) => ['indexing', 'indexed', 'success'].indexOf(ap.status) !== -1,
  )[0];

  const [selectedResultAudienceName, setSelectedResultAudienceName] = useState<string | undefined>(undefined);
  const [matchingOptionName, setMatchingOptionName] = useState<string | undefined>(undefined);
  const [currentStage, setCurrentStage] = useState<SegmentStage | SmartSegmentStage>(
    segment?.smartBuilder?.active ? 'Smart Builder' : 'Definition',
  );
  const editableSegment = activationProcess === undefined;

  const stages = ['Definition', 'Matching'] as SegmentStage[];
  const smartStages = ['Smart Builder', 'Definition'] as SmartSegmentStage[];

  const handleActivateStudioDataBricksMatch = useCallback(() => {
    if (activateStudioState.activateState?.enableMatching) {
      dispatch(
        dataBricksMatching({
          audienceRecordId: segment.amdmInfos?.audienceRecordId as string,
          selectedCountry: segment.countryIsos[0],
        }),
      );
    } else if (
      activateStudioState.activateState?.data.find(({ statusActivateStudio }) => statusActivateStudio === 'READY')
    ) {
      const provider = (
        activateStudioState.activateState.data.find(({ provider }) => provider === 'AUDIENCE_STUDIO') ||
        activateStudioState.activateState.data[0]
      ).provider.toLocaleLowerCase();
      window.open(
        `${
          config.converged.convergedUrl as string
        }/converged/#/tools/activate/native/activateStudio/3/${provider}/push/${
          segment.amdmInfos?.audienceRecordId as string
        }`,
        '_blank',
      );
    } else if (
      activateStudioState.activateState?.data.find(({ statusActivateStudio }) => statusActivateStudio === 'MATCHED')
    ) {
      const provider = (
        activateStudioState.activateState.data.find(({ provider }) => provider === 'AUDIENCE_STUDIO') ||
        activateStudioState.activateState.data[0]
      ).provider.toLocaleLowerCase();

      window.open(
        `${config.converged.convergedUrl as string}/converged/#/tools/activate/native/activateStudio/3/${provider}/${
          segment.amdmInfos?.audienceRecordId as string
        }`,
        '_blank',
      );
    }
  }, [segment, dataBricksMatching, activateStudioState, config, currentStage]);

  const getStageClassName = useCallback(
    (option: SegmentStage) => {
      if (option === 'Definition' || option === 'Smart Builder') return '';
      else if (option === 'Matching' && segment.amdmInfos?.audienceRecordId) {
        if (
          !activateStudioState.activateState ||
          activateStudioState.activateState?.data.find(({ statusActivateStudio }) =>
            ['PUSHED'].includes(statusActivateStudio),
          )
        )
          return 'unselectable';

        if (!activateStudioState.activateState?.data.length) return '';

        const activationStatus = activateStudioState.activateState?.data.find(({ statusActivateStudio }) =>
          ['MATCHING', 'MATCHED', 'READY'].includes(statusActivateStudio),
        );

        if (!activationStatus) return 'unselectable';
        else return '';
      }

      return 'unselectable';
    },
    [segment, activateStudioState],
  );

  const handleSettings = () => {
    if (segment.smartBuilder?.recommanderStatus !== 'SUCCESS' || !segment.smartBuilder.active || loading) return;
    if (selectedStage === 'Definition') setSelectedStage('Smart Builder');
    dispatch(
      segmentSlice.actions.setSmartLevel({
        id: segment.id,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
        level:
          selectedStage === 'Definition' && segment.smartBuilder?.level === '1'
            ? '0'
            : selectedStage === 'Smart Builder' && segment.smartBuilder?.level === '0'
            ? '1'
            : '0',
      }),
    );
  };

  const handelSaveSmart = () => {
    setLoading(true);
    dispatch(
      segmentSlice.actions.setSmartAudienceBuilderStatus({
        id: segment.id,
        audienceBuilderStatus: 'RUNNING',
      }),
    );

    if (segment.smartBuilder?.audienceBuilderRunIds.length !== 0) {
      dispatch(
        segmentSlice.actions.setSmartAudienceBuilderRunIds({
          id: segment.id,
          runIds: [],
        }),
      );
    }

    type CombinedObj = {
      [key: string]: {
        [key: string]: string;
      };
    };
    // eslint-disable-next-line
    const findNewOptions = segment.smartBuilder?.selectedSubAudiencies
      .map((selectedSubAud) => {
        // eslint-disable-next-line
        const savedSubAud = segment.smartBuilder?.savedSubAudiencies.find(
          (savedSubAud) => savedSubAud.index === selectedSubAud.index,
        );
        if (savedSubAud) {
          // eslint-disable-next-line
          const newOpt = selectedSubAud.options.filter((opt) => !savedSubAud.options.includes(opt));
          return newOpt.length ? { index: selectedSubAud.index, options: newOpt } : null;
        }
        return selectedSubAud;
      })
      .filter((options) => options !== null);

    if (!findNewOptions) return;

    const audienceGrouped = findNewOptions.reduce<CombinedObj[]>((list, item) => {
      if (!item) return list;
      const sentiment = segment.smartBuilder?.recommander[item.index].title || '';

      item.options.map((option) => {
        const valueId = segment.smartBuilder?.recommander[item.index].options[option].value || '';
        const label = segment.smartBuilder?.recommander[item.index].options[option].label || '';
        const isExists = list.findIndex((obj) => obj[sentiment]);

        if (isExists !== -1) {
          list[isExists][sentiment][valueId] = label;
        } else {
          const newSentiment = {
            [sentiment]: {
              [valueId]: label,
            },
          };

          list.push(newSentiment);
        }
      });

      return list;
    }, []);

    if (audienceGrouped) setSubAudiences(audienceGrouped);
  };

  useEffect(() => {
    let interval: NodeJS.Timer | null = null;

    if (
      segment &&
      segment.amdmInfos &&
      segment.amdmInfos.audienceRecordId &&
      activateStudioState.activateState?.data.find(({ statusActivateStudio }) => statusActivateStudio === 'MATCHING')
    ) {
      interval = setInterval(() => {
        dispatch(
          getActivateStudioState({
            audienceRecordId: segment.amdmInfos?.audienceRecordId as string,
            selectedCountry: segment.countryIsos[0],
          }),
        );
      }, 5000);
    } else if (
      interval &&
      segment &&
      segment.amdmInfos &&
      segment.amdmInfos.audienceRecordId &&
      activateStudioState.activateState?.data.find(({ statusActivateStudio }) => statusActivateStudio === 'MATCHED')
    ) {
      clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [segment, getActivateStudioState, dispatch, activateStudioState]);

  return (
    <UJContext.Provider
      value={{
        segment,
        stages,
        currentStage: (segment.smartBuilder?.active ? 'Smart Builder' : 'Definition') as SegmentStage,
        selectedStage,
        setSelectedStage,
        handleActivateStudioDataBricksMatch,
        handleBreadCrumbSelection,
        enableExport,
        setEnableExport,
        setMatchingModalOpen,
        matchingModalOpen,
        selectedResultAudienceName,
        setSelectedResultAudienceName,
        matchingOptionName,
        setMatchingOptionName,
      }}
    >
      <div className="segment-editor">
        {warningOpen && (
          <Warning
            title={'Panel Size'}
            content={'The panel size is below 3,000 panelists. Please adjust to meet the minimum requirement.'}
            setWarningOpen={setWarningOpen}
          />
        )}
        <SegmentEditorHeader
          handleActivateStudioDataBricksMatch={handleActivateStudioDataBricksMatch}
          urlBack={urlBack}
          handleSettings={handleSettings}
          setSelectedStage={setSelectedStage}
          setCurrentStage={setCurrentStage}
          setWarningOpen={setWarningOpen}
          loading={loading}
        />
        <>
          {!segment?.smartBuilder?.active ? (
            <>
              <div>
                <ul className="breadcrumb">
                  {stages.map((option, i) => (
                    <li
                      className={`${option === selectedStage ? 'selected' : ''} ${getStageClassName(option)}`}
                      onClick={() => handleBreadCrumbSelection(option)}
                      key={i}
                    >
                      <div className="stage-name">{option}</div>
                    </li>
                  ))}
                </ul>
              </div>
              {selectedStage === 'Definition' && <DefinitionStage disabled={!editableSegment} />}
              {selectedStage === 'Matching' && <MatchingStage />}
              {/* {selectedStage === 'Activation' && <ActivationStage />} */}
            </>
          ) : (
            <>
              <div>
                <ul className="breadcrumb">
                  {smartStages.map((option, i) => {
                    return (
                      <li
                        className={`${
                          option === selectedStage
                            ? 'selected'
                            : segment.smartBuilder?.audienceBuilderStatus !== 'SUCCESS'
                            ? 'disabledSelection'
                            : ''
                        }`}
                        onClick={() => handleBreadCrumbSelection(option)}
                        key={i}
                      >
                        <div className="stage-name">{option}</div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {selectedStage === 'Smart Builder' && (
                <>
                  <div className="editor-content-border">
                    {runFailed && <div style={{ color: 'red' }}>Error on load data, please try again.</div>}
                    {loading && (
                      <div className="loading">
                        <Spin
                          tip={'The Smart builder analysis is saving data. Please wait, it might take 2-3 minutes…...'}
                          indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}
                        />
                      </div>
                    )}
                    {!loading && !runFailed && (
                      <SmartBuilder
                        selectedStage={currentStage}
                        segment={segment}
                        smartStage={segment.smartBuilder.level}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                      />
                    )}
                  </div>
                  {segment.smartBuilder.level === '1' && (
                    <div className="footer">
                      <div className="btn--sm btn--full sb" onClick={() => handelSaveSmart()}>
                        <SaveOutlined style={{ fontSize: '16px', paddingRight: '5px' }} />
                        <span>Save</span>
                      </div>
                    </div>
                  )}
                </>
              )}

              {selectedStage === 'Definition' && <DefinitionStage disabled={!editableSegment} />}
            </>
          )}
        </>
      </div>
      {matchingModalOpen && !segment?.smartBuilder?.active && <MatchingModal />}
    </UJContext.Provider>
  );
};

export default SegmentEditor;
