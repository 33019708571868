import { FC, useState, useEffect } from 'react';
import './SegmentImporter.less';
import { IconShare, IconRemove } from '../Svg/HavasIcons';
import { SearchOutlined } from '@ant-design/icons';
import { Popover } from './Popover';
import { ActivationProcess } from '../store/activationProcessSlice';
import { MdAttribution } from 'react-icons/md';
import { HiOutlineRefresh } from 'react-icons/hi';
import { useLocation } from 'react-router';
import AutocompleteBar from './AutoCompleteBar';
import { SegmentWithUiMeta } from 'hawaii';
import { extractPath } from '../../core/helpers/extractLocation';
interface Props {
  text: string;
  segments: SegmentWithUiMeta[];
  sharedSegments: SegmentWithUiMeta[];
  activationProcesses: ActivationProcess[];
  onSelect: (segment: SegmentWithUiMeta) => void;
  newSegmentAction: (isSmart?: boolean) => SegmentWithUiMeta;
  deleteAction: (segment: SegmentWithUiMeta) => void;
  shareUnshareAction: (segment: SegmentWithUiMeta) => void;
  handleSmartBuilder?: (newSegment: SegmentWithUiMeta) => void;
}

const SegmentImporter: FC<Props> = ({
  text,
  segments,
  sharedSegments,
  activationProcesses,
  onSelect,
  newSegmentAction,
  deleteAction,
  shareUnshareAction,
  handleSmartBuilder,
}) => {
  const [selectedTab, setSelectebTab] = useState<'Personal' | 'Shared'>('Personal');
  const [selectedAutoComplete, setSelectedAutoComplete] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [segmentsData, setSegmentsData] = useState(segments);
  const location = useLocation();

  useEffect(() => {
    if (selectedAutoComplete === '') {
      if (selectedTab === 'Personal') {
        setSegmentsData(segments);
      }

      if (selectedTab === 'Shared') {
        setSegmentsData(sharedSegments);
      }

      return;
    }

    if (selectedTab === 'Personal') {
      setSegmentsData(
        segments.filter((segment) => segment.name.toLowerCase().includes(selectedAutoComplete.toLowerCase())),
      );
    }

    if (selectedTab === 'Shared') {
      setSegmentsData(
        sharedSegments.filter((segment) => segment.name.toLowerCase().includes(selectedAutoComplete.toLowerCase())),
      );
    }
  }, [selectedAutoComplete, segments, selectedTab]);

  useEffect(() => {
    if (open) return;

    setSelectedAutoComplete('');
  }, [open]);

  const item = (segment: SegmentWithUiMeta) => (
    <>
      <div className="segment-item__color" style={{ backgroundColor: segment.color }}>
        {!segment.shared ? <>&nbsp;</> : <IconShare className="c-icon-fill c-icon--16 c-icon--primary" />}
      </div>
      <span className="segment-item__name">{segment.name}</span>
    </>
  );

  const personalItem = (segment: SegmentWithUiMeta, ap: ActivationProcess | undefined) => {
    return (
      <li
        className="segment-item"
        key={segment.id}
        onClick={() => {
          onSelect(segment);
        }}
      >
        {item(segment)}
        <div className="segment-item__actions">
          <div
            className="segment-item__icon"
            title={segment.shared ? 'Unshare' : 'Share'}
            onClick={(e) => {
              shareUnshareAction(segment);
              e.stopPropagation();
            }}
          >
            <IconShare className="c-icon-fill c-icon--16 c-icon--primary" />
          </div>
          <div
            className="segment-item__icon"
            title="Delete"
            onClick={(e) => {
              deleteAction(segment);
              e.stopPropagation();
            }}
          >
            <IconRemove className="c-icon-stroke c-icon--16"></IconRemove>
          </div>
        </div>
        <div style={{ display: 'inline-block', position: 'relative', top: '0.2em', color: segment.color }}>
          {ap && ap.status === 'indexed' && <MdAttribution />}
          {ap && ap.status === 'success' && <HiOutlineRefresh />}
        </div>
      </li>
    );
  };

  const handleAutoCompleteAction = (v: string) => setSelectedAutoComplete(v);

  const personal = () => (
    <ul className="scrollable">
      <AutocompleteBar
        selectedValue={selectedAutoComplete}
        icon={!selectedAutoComplete ? <SearchOutlined /> : <span />}
        options={segmentsData.map((segment) => {
          return { label: segment.name, key: segment.id, value: segment.name };
        })}
        handleAutoCompleteAction={handleAutoCompleteAction}
      />
      {segmentsData.flatMap((segment) => {
        return personalItem(
          segment,
          activationProcesses.find((ap: ActivationProcess) => ap.segmentId === segment.id),
        );
      })}
    </ul>
  );

  const shared = () => (
    <ul className="scrollable">
      <AutocompleteBar
        selectedValue={selectedAutoComplete}
        icon={!selectedAutoComplete ? <SearchOutlined /> : <span />}
        options={segmentsData.map((segment) => {
          return { value: segment.name, name: segment.name };
        })}
        handleAutoCompleteAction={handleAutoCompleteAction}
      />
      {segmentsData.map((segment, index) => (
        <li
          className="segment-item"
          key={index}
          onClick={() => {
            onSelect(segment);
          }}
        >
          {item(segment)}
          <div style={{ display: 'inline-block', position: 'relative', top: '0.2em', color: segment.color }}>
            {segment.activationStatus === 'indexed' && <MdAttribution />}
            {segment.activationStatus === 'success' && <HiOutlineRefresh />}
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <Popover
      placement="top-end"
      isOpen={open}
      openClose={setOpen}
      render={() => (
        <div className="segment-importer-container">
          <div className="menu">
            <div className="tabs">
              <div
                className={selectedTab === 'Personal' ? 'selected' : ''}
                onClick={() => {
                  setSelectebTab('Personal');
                }}
              >
                Personal
              </div>
              <div
                className={selectedTab === 'Shared' ? 'selected' : ''}
                onClick={() => {
                  setSelectebTab('Shared');
                }}
              >
                Shared
              </div>
            </div>
            {selectedTab === 'Personal' ? personal() : shared()}

            {extractPath(location.pathname) !== 'cluster-builder' &&
              extractPath(location.pathname) !== '/cluster-editor' && (
                <div className="action">
                  <p>Add new Audience</p>
                  <div className="action__btns">
                    <input
                      className="btn--sm"
                      type="button"
                      value="Manual"
                      onClick={() => {
                        newSegmentAction();
                      }}
                    ></input>
                    {/* <input
                      className="btn--sm"
                      type="button"
                      value="Smart Builder"
                      onClick={() => {
                        const newSegment = newSegmentAction(true);
                        handleSmartBuilder && handleSmartBuilder(newSegment);
                        setOpen(false);
                      }}
                    ></input> */}
                  </div>
                </div>
              )}

            {(extractPath(location.pathname) === 'cluster-builder' ||
              extractPath(location.pathname) === '/cluster-editor') && (
              <div className="action">
                <input
                  className="btn--sm btn--full"
                  type="button"
                  value="Add new Audience"
                  onClick={() => {
                    newSegmentAction();
                  }}
                ></input>
              </div>
            )}
          </div>
        </div>
      )}
    >
      <input type="button" className="toogle-segment-importer" value={text}></input>
    </Popover>
  );
};

export default SegmentImporter;
