import './settings.less';
import { FC, useState } from 'react';
import { Row, Col } from 'antd';
import Selector from './selector';
import SearchBar from './searchBar';

interface Props {
  handleSearch: (prompt: string) => void;
  setMaxAlternate: React.Dispatch<React.SetStateAction<number>>;
  setMaxResults: React.Dispatch<React.SetStateAction<number>>;
  setValResults: React.Dispatch<React.SetStateAction<boolean>>;
  setMatchCriteria: React.Dispatch<React.SetStateAction<string>>;
  maxAlternate: number;
  maxResults: number;
  valResults: boolean;
  matchCriteria: string;
  defaultPrompt: string;
}

const Settings: FC<Props> = ({
  handleSearch,
  setMaxAlternate,
  setMaxResults,
  setValResults,
  setMatchCriteria,
  maxAlternate,
  maxResults,
  valResults,
  matchCriteria,
  defaultPrompt,
}) => {
  const generateOptions = (
    arr: (string | number | boolean)[],
  ): { value: string | number | boolean; label: string }[] => {
    return arr.map((item: string | number | boolean) => {
      let label: string;
      if (typeof item === 'boolean') {
        label = item ? 'Yes' : 'No';
      } else {
        label = item.toString();
      }

      return { value: item, label: label };
    });
  };

  return (
    <div className="settings_container">
      <h3>Settings</h3>
      <div className="selectors">
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <Selector
              label={'Max alternate alt-segments to generate per segment'}
              toolTip={
                'Number of alt-segments to generate per segment: Automatically generates alternative segments that aim to capture the identity of the segment in a differ way. e.g. [3] “on a budget” -> “affordable”, “cost effective”, “budget conscious”'
              }
              options={generateOptions([1, 2, 3, 4])}
              defaultValue={maxAlternate}
              handleChange={setMaxAlternate}
            />
          </Col>
          <Col span={12}>
            <Selector
              label={'Apply result validation'}
              toolTip={
                'Should a second validation step be applied to ensure the top n segments are accurate?: Pros: Filters out poorly matching alias Cons: Takes slightly longer to run'
              }
              options={generateOptions([true, false])}
              defaultValue={valResults}
              handleChange={setValResults}
            />
          </Col>
          <Col span={12}>
            <Selector
              label={'Max result to generat per alt-segment'}
              toolTip={'How many alias should be generated per alt-segment: Feeds back the top n segments.'}
              options={generateOptions([1, 2, 3, 4])}
              defaultValue={maxResults}
              handleChange={setMaxResults}
            />
          </Col>
          <Col span={12}>
            <Selector
              label={'Matching criteria'}
              toolTip={
                'If second validation is applied how strict should the alias matching be? High: Only alias with close to perfect matching scores are returned Medium: A balanced approach Low: Only those alias with close to 0 matching score are removed'
              }
              options={generateOptions(['High', 'Medium', 'Low'])}
              defaultValue={matchCriteria}
              handleChange={setMatchCriteria}
            />
          </Col>
        </Row>
      </div>
      <div className="searchPanel">
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <SearchBar defaultValue={defaultPrompt} handleSearch={handleSearch} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Settings;
