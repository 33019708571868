import './table.less';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import Color from 'color';
import { useDispatch, useSelector } from 'react-redux';
import {
  SaveOutlined,
  CaretLeftFilled,
  CaretRightFilled,
  CheckCircleOutlined,
  RadarChartOutlined,
  PlusSquareOutlined,
  IssuesCloseOutlined,
} from '@ant-design/icons';
import { Tooltip, Select, Modal, Divider, Input, Radio, Button } from 'antd';
import { useClustersData } from '../hooks/useClustersData';
import { GlobalState } from '../../../store/global';
import SpiderChart from './spider-chart';
import { RespondentsData } from '../../common/interface/interface';
import { IconChevronRight } from '../../../Svg/HavasIcons';
import { IconChevronLeft } from '../../../Svg/HavasIcons';
import { getMdmDataThunk } from '../../../store/thunk/secmdm/getMdmData';
import { countryMetadatas } from 'hawaii';
import { setClientInputValue, setIsPitchClient, setSelectedClient } from '../../../store/secmdmSlice';

const { Option } = Select;

interface DataType {
  question: string;
  response: string;
  clusters: { default: number; percentage: number }[];
  respondents: {
    [key: string]: RespondentsData[];
  };
  marketSize: string;
  colors: string[] | undefined;
}

interface Props {
  data: DataType[];
  title: string;
  segmentID: string;
  marketSize: { [key: string]: number };
}

const Table: FC<Props> = ({ data, title, segmentID, marketSize }) => {
  const { saveClusterAMDM, errorClusterSave, setErrorClusterSave } = useClustersData(segmentID, 'clusters');
  const [sortedData, setSortedData] = useState<DataType[]>(data);
  const [arrowDirection, setArrowDirections] = useState<'right' | 'left'>('right');
  const [colors, setColors] = useState<string[] | undefined>(undefined);
  const [responsesSortOrder, setResponsesSortOrder] = useState<'ASC' | 'DESC' | ''>('');
  const [clustersSortOrder, setClustersSortOrder] = useState<'ASC' | 'DESC' | ''>('');
  const [uniqueQuestions, setUniqueQuestions] = useState<{ label: string; value: string }[]>();
  const [clasterLabels, setClasterLabels] = useState<string[] | []>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [agencyList, setAgencyList] = useState<{ value: string; label: string }[] | null>(null);
  const [clientList, setClientList] = useState<{ value: string; label: string }[] | null>(null);
  const [key1, setKey1] = useState<string>('');
  const [key2, setKey2] = useState<string>('');
  const [isPitch, setIsPitch] = useState<boolean>(false);
  const [selectedAgency, setSelectedAgency] = useState<number>();
  const [selectedCluster, setSelectedCluster] = useState<number>();
  const [audienceClusterName, setAudienceClusterName] = useState<string>();
  const [spiderView, setSpiderView] = useState<boolean>(false);
  const { agencies, clients } = useSelector((state: GlobalState) => state.secmdmState);
  const selectedSegment = useSelector((state: GlobalState) =>
    state.segments.find((segment) => segment.publicId === segmentID),
  );
  const [valueState, setValueState] = useState<keyof { default: number; percentage: number }>('default');
  const { savedResults } = useSelector((state: GlobalState) => state.databricks['clusters']?.[segmentID]);
  const container = document.querySelector('.table-container');
  const dispatch = useDispatch();
  const [selectorInputValue, setSelectorInputValue] = useState<string>('');
  const [agencyError, setAgencyError] = useState<boolean>(false);

  useEffect(() => {
    if (!agencies || !clients) return;
    setAgencyList(
      agencies.list
        .map((agency) => ({
          value: agency.id.toString(),
          label: agency.name,
        }))
        .sort((a, b) => a?.label.localeCompare(b?.label)),
    );
    setClientList(
      clients.list
        .map((client) => ({
          value: client.id.toString(),
          label: client.name === null ? '- No name' : client.name,
        }))
        .sort((a, b) => a?.label.localeCompare(b?.label)),
    );
  }, [agencies, clients]);

  // update agencies and clients list when model opens
  const countries = useSelector((state: GlobalState) => state.secmdmState).countries.list;
  const validCountries = useMemo(
    () => countries.filter(({ iso }) => countryMetadatas.find((country) => country.iso === iso)),
    [countries],
  );

  const selectedCountry = useSelector((state: GlobalState) => state.secmdmState.countries.selected);

  useEffect(() => {
    if (modalVisible && selectedCountry) {
      const countriesId = [];
      const countryId = validCountries.find(({ iso }) => iso === selectedCountry.iso)?.id;
      if (countryId) {
        countriesId.push(countryId);
      }
      dispatch(getMdmDataThunk(countriesId));
    }
  }, [modalVisible]);

  useEffect(() => {
    if (!data || !segmentID) return;
    if (errorClusterSave) {
      setErrorClusterSave(null);
    }
    setClasterLabels(data[0]?.clusters.map((_, index) => `Cluster ${index + 1}`));
    setColors(data[0]?.colors);
    const unqiqueQuestionsList = [...new Set(data.map((row) => row.question))].map((item) => ({
      label: item,
      value: item,
    }));
    unqiqueQuestionsList.unshift({ label: 'All', value: 'all' });
    setUniqueQuestions(unqiqueQuestionsList);
  }, [data, segmentID]);

  const OPTIONS = uniqueQuestions;

  useEffect(() => {
    if (!uniqueQuestions) return;
    if (uniqueQuestions[0].value === 'all') {
      setSortedData(data);
    } else {
      setSortedData(data.filter((item) => item.question === uniqueQuestions[0].label));
    }
  }, [uniqueQuestions]);

  useEffect(() => {
    if (!container) return;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    container.addEventListener('scroll', handleScroll as any);

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      container.removeEventListener('scroll', handleScroll as any);
    };
  }, [container]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const container = e.target as HTMLDivElement;
    if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
      setArrowDirections('left');
    } else if (container.scrollLeft === 0) {
      setArrowDirections('right');
    }
  };

  const handleArrowClick = () => {
    const container = document.querySelector('.table-container');
    if (container) {
      const maxScrollRight = container.scrollWidth - container.clientWidth;

      if (arrowDirection === 'right') {
        container.scrollBy({ left: maxScrollRight, behavior: 'smooth' });
        setArrowDirections('left');
      } else {
        container.scrollBy({ left: -maxScrollRight, behavior: 'smooth' });
        setArrowDirections('right');
      }
    }
  };

  type GrupedDataType = {
    [key: string]: DataType[];
  };

  const grupedData = sortedData.reduce<GrupedDataType>((acc, row) => {
    if (!acc[row.question]) {
      acc[row.question] = [];
    }
    acc[row.question].push(row);
    return acc;
  }, {});

  const onChange = (value: string) => {
    if (value === 'all') {
      setSortedData(data);
    } else {
      setSortedData(data.filter((item) => item.question === value));
    }
  };

  const handleSortByResponses = () => {
    const dataToSort = [...sortedData];

    if (responsesSortOrder === '') {
      setSortedData(dataToSort);
    }

    const summedClustersByResponses: Record<string, number[]> = {};

    dataToSort.forEach((item) => {
      if (!summedClustersByResponses[item.response]) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        summedClustersByResponses[item.response] = Array(item.clusters.length).fill(0);
      }
      item.clusters.forEach((cluster, index) => {
        summedClustersByResponses[item.response][index] +=
          valueState !== 'default' ? Number((cluster[valueState] * 100).toFixed()) : cluster[valueState];
      });
    });

    dataToSort.sort((a, b) => {
      const totalA = summedClustersByResponses[a.response].reduce((acc, curr) => acc + curr, 0);
      const totalB = summedClustersByResponses[b.response].reduce((acc, curr) => acc + curr, 0);
      return responsesSortOrder === 'ASC' ? totalA - totalB : totalB - totalA;
    });
    setResponsesSortOrder(responsesSortOrder === 'ASC' ? 'DESC' : responsesSortOrder === 'DESC' ? '' : 'ASC');
    setSortedData(dataToSort);
  };

  const handleClustersSort = (index: number) => {
    const dataToSort = [...sortedData];

    if (clustersSortOrder === '') {
      setSortedData(dataToSort);
    }
    dataToSort.sort((a, b) => {
      return clustersSortOrder === 'ASC'
        ? a.clusters[index][valueState] - b.clusters[index][valueState]
        : b.clusters[index][valueState] - a.clusters[index][valueState];
    });

    setClustersSortOrder(clustersSortOrder === 'ASC' ? 'DESC' : clustersSortOrder === 'DESC' ? '' : 'ASC');
    setSortedData(dataToSort);
  };

  const handleDragStart = (e: React.DragEvent, key: string) => {
    const index1 = clasterLabels.findIndex((item) => item === key);
    setKey1(index1.toString());
  };
  const handleDragOver = (e: React.DragEvent, key: string) => {
    e.preventDefault();
    const index2 = clasterLabels.findIndex((item) => item === key);
    setKey2(index2.toString());
  };

  const handleOnDrop = (e: React.DragEvent) => {
    e.preventDefault();
    if (!sortedData || !colors || !clasterLabels) return;
    const dataToSwap = [...sortedData];
    const labelsToSwap = [...clasterLabels];
    const colorsToSwap = [...colors];

    dataToSwap.forEach((item) => {
      if (Number(key1) < item.clusters.length && Number(key2) < item.clusters.length) {
        [item.clusters[Number(key1)], item.clusters[Number(key2)]] = [
          item.clusters[Number(key2)],
          item.clusters[Number(key1)],
        ];
      }
    });

    [labelsToSwap[Number(key1)], labelsToSwap[Number(key2)]] = [labelsToSwap[Number(key2)], labelsToSwap[Number(key1)]];
    [colorsToSwap[Number(key1)], colorsToSwap[Number(key2)]] = [colorsToSwap[Number(key2)], colorsToSwap[Number(key1)]];

    setSortedData(dataToSwap);
    setClasterLabels(labelsToSwap);
    setColors(colorsToSwap);
  };

  const handleToSaveCluster = (index: number) => {
    setSelectedCluster(index);
    setAudienceClusterName(`${selectedSegment ? selectedSegment?.name : 'Audience'} - ${clasterLabels[index]}`);
    setModalVisible(true);
  };
  const handleSaveCluster = async () => {
    if (!selectedAgency) {
      setAgencyError(true);
      return;
    } else {
      setAgencyError(false);
    }

    const repondents = data[0].respondents[Number(selectedCluster)];
    const metaEntity = {
      audienceClusterName,
      selectedAgency: selectedAgency,
      selectedClient: clients.selected || clients.clientInputValue || -1,
      selectedCluster,
      marketSize: Object.values(marketSize)[selectedCluster || 0],
    };

    await saveClusterAMDM(metaEntity, repondents);
    setModalVisible(false);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setAudienceClusterName(undefined);
    setSelectedAgency(undefined);
    setSelectedCluster(undefined);
    setSelectedClient(undefined);
    dispatch(setSelectedClient(undefined));
    dispatch(setClientInputValue(''));
    setSelectorInputValue('');
    setIsPitch(false);
  };

  const clientSelectorBlur = () => {
    const selector = document.getElementById('ClientSelector');
    if (selector) selector.blur();
  };

  const clientSelector = () => {
    const selector = () => {
      return (
        <>
          <Select
            id="ClientSelector"
            value={clients.clientInputValue || clients.selected}
            showSearch
            placeholder={clients.isPitchClient ? 'Select your pitch client from the list' : 'Select your client'}
            filterOption={(inputValue, option) => {
              if (!option || typeof option.children !== 'string') {
                return false;
              }
              return (option.children as string).toLowerCase().includes(inputValue.toLowerCase());
            }}
            allowClear
            onClear={() => {
              dispatch(setSelectedClient(undefined));
              dispatch(setClientInputValue(''));
            }}
            showArrow
            style={{ width: '100%' }}
            onSelect={(client, opt) => {
              dispatch(setSelectedClient(Number(opt.key as number)));
              dispatch(setClientInputValue(''));
            }}
            onSearch={(event) => {
              setSelectorInputValue(event);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                dispatch(setClientInputValue(selectorInputValue));
                clientSelectorBlur();
              }
            }}
            dropdownRender={(menu) => (
              <div>
                {menu}
                {clients.isPitchClient && (
                  <>
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                      style={{ padding: '4px 8px', cursor: 'pointer', color: '#373737' }}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        dispatch(setClientInputValue(selectorInputValue));
                        clientSelectorBlur();
                      }}
                    >
                      <PlusSquareOutlined /> Add New Pitch client
                    </div>
                  </>
                )}
              </div>
            )}
          >
            {clients.list
              .filter((c) => !c.isPitch)
              .map((client) => (
                <Option key={client.id} value={client.id}>
                  {client.name}
                </Option>
              ))}
          </Select>
        </>
      );
    };

    return (
      <>
        <div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: '8px' }}>
            <span style={{ width: '10%', textAlign: 'left', marginRight: '20px' }}>Client:</span>
            <span style={{ marginRight: '8px' }}>Is it a Pitch -</span>
            <Radio.Group
              onChange={(e) => {
                dispatch(setIsPitchClient(e.target.value as boolean));
                dispatch(setClientInputValue(''));
                dispatch(setSelectedClient(undefined));
                setIsPitch(e.target.value as boolean);
              }}
              value={clients.isPitchClient}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>{selector()}</div>
        </div>
      </>
    );
  };

  return (
    <>
      {spiderView && <SpiderChart setSpiderView={setSpiderView} />}
      {modalVisible && agencyList && clientList && (
        <Modal
          title="Save audience cluster in AMDM direction."
          open={true}
          onCancel={handleCloseModal}
          onOk={() => handleSaveCluster()}
          okText={'Save'}
          centered={true}
          okButtonProps={{ disabled: !audienceClusterName }}
        >
          <h3>Cluster name</h3>
          <Input
            placeholder="Basic usage"
            defaultValue={audienceClusterName}
            onChange={(e) => setAudienceClusterName(e.target.value)}
          />
          <Divider />
          <h3>Select agency and client.</h3>
          <h4>Agency:</h4>
          {
            <div style={{ height: '22px', color: 'red', marginLeft: '30%', marginTop: '12px' }}>
              {agencyError ? 'Please Select an agency' : ''}
            </div>
          }
          <Select
            style={{ width: '100%' }}
            showSearch
            placeholder="Select a agency"
            optionFilterProp="children"
            onChange={(value: string) => setSelectedAgency(Number(value))}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={agencyList}
          />
          <Divider />
          {clientSelector()}
        </Modal>
      )}
      {OPTIONS && (
        <>
          <div className="cluster-title">
            <h2>{title}</h2>
            <Radio.Group
              style={{ marginLeft: '5px' }}
              onChange={() => setValueState((prevValue) => (prevValue === 'default' ? 'percentage' : 'default'))}
              value={valueState}
            >
              <Radio value={'default'}>Index</Radio>
              <Radio value={'percentage'}>%</Radio>
            </Radio.Group>
          </div>
          <div className="cluster-table">
            <div className="table-header">
              <span className="title">
                <span className="text">Questions:</span>
                <div className="selector">
                  <Select
                    showSearch
                    placeholder="Select a question"
                    optionFilterProp="children"
                    onChange={onChange}
                    defaultValue={OPTIONS[0].label}
                    key={OPTIONS[0].label}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={OPTIONS}
                  />
                </div>
                <Button
                  shape="default"
                  style={{ display: 'flex', justifyContent: 'center' }}
                  icon={<RadarChartOutlined style={{ fontSize: '24px' }} />}
                  onClick={() => setSpiderView(!spiderView)}
                />
              </span>

              <div className="arrows">
                {clasterLabels.length >= 3 && (
                  <span className={`scroll-arrows ${arrowDirection}`} onClick={handleArrowClick}>
                    <span>
                      {arrowDirection === 'right' ? (
                        <IconChevronRight className="c-icon-stroke c-icon--20" />
                      ) : (
                        <IconChevronLeft className="c-icon-stroke c-icon--20" />
                      )}
                    </span>
                    <span>
                      {arrowDirection === 'right' ? (
                        <IconChevronRight className="c-icon-stroke c-icon--20" />
                      ) : (
                        <IconChevronLeft className="c-icon-stroke c-icon--20" />
                      )}
                    </span>
                    <span>
                      {arrowDirection === 'right' ? (
                        <IconChevronRight className="c-icon-stroke c-icon--20" />
                      ) : (
                        <IconChevronLeft className="c-icon-stroke c-icon--20" />
                      )}
                    </span>
                  </span>
                )}
              </div>
            </div>
            <div className="table-container" style={{ overflow: clasterLabels.length >= 3 ? 'auto' : 'unset' }}>
              <table>
                <thead>
                  <tr style={{ borderBottom: '2px solid #25cacf', position: 'sticky', top: '0' }}>
                    <th style={{ backgroundColor: '#62626214' }} className="label">
                      <div className="sort-container">Question</div>
                    </th>

                    <th style={{ backgroundColor: '#62626214' }} className="label">
                      <div className="sort-container">
                        Responses
                        <div className="icons" onClick={handleSortByResponses}>
                          <CaretLeftFilled
                            style={{
                              fontSize: '12px',
                              color: `${responsesSortOrder === 'DESC' ? '#25cad0' : 'gray'}`,
                              width: '35%',
                            }}
                          />
                          <CaretRightFilled
                            style={{
                              fontSize: '12px',
                              color: `${responsesSortOrder === 'ASC' ? '#25cad0' : 'gray'}`,
                              width: '35%',
                            }}
                          />
                        </div>
                      </div>
                    </th>

                    {
                      /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
                      clasterLabels.map((cluster, index) => (
                        <Fragment key={index}>
                          <th
                            draggable="true"
                            onDragStart={(e) => handleDragStart(e, cluster)}
                            onDragOver={(e) => handleDragOver(e, cluster)}
                            onDrop={(e) => handleOnDrop(e)}
                            style={{
                              background: colors ? lightColor(colors[index]) : '#fff',
                            }}
                            className="labels-container"
                          >
                            <div className="cluster-labels">
                              {(savedResults as number[])?.includes(index) && !errorClusterSave ? (
                                <span className="save-btn" style={{ cursor: 'default' }}>
                                  <CheckCircleOutlined style={{ fontSize: '16px', color: '#23db23' }} />
                                </span>
                              ) : errorClusterSave === index ? (
                                <span className="save-btn" style={{ cursor: 'default' }}>
                                  <IssuesCloseOutlined style={{ fontSize: '16px', color: 'red' }} />
                                </span>
                              ) : (
                                <span className="save-btn" onClick={() => handleToSaveCluster(index)}>
                                  <SaveOutlined style={{ fontSize: '16px' }} />
                                </span>
                              )}

                              <div className="sort-container">
                                <span style={{ cursor: 'grab' }}>{cluster}</span>
                                <div className="icons" onClick={() => handleClustersSort(index)}>
                                  <CaretLeftFilled
                                    style={{
                                      fontSize: '12px',
                                      color: `${clustersSortOrder === 'DESC' ? '#25cad0' : 'gray'}`,
                                      width: '35%',
                                    }}
                                  />
                                  <CaretRightFilled
                                    style={{
                                      fontSize: '12px',
                                      color: `${clustersSortOrder === 'ASC' ? '#25cad0' : 'gray'}`,
                                      width: '35%',
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="cluster-info">
                              <span>
                                R:
                                {data[0]?.respondents[
                                  parseInt(cluster.replace(/^\D+/g, ''), 10) - 1
                                ]?.length?.toLocaleString() || 0}
                              </span>{' '}
                              /{' '}
                              <span>
                                M:
                                {Object.values(marketSize)[
                                  parseInt(cluster.replace(/^\D+/g, ''), 10) - 1
                                ]?.toLocaleString() || 0}
                              </span>
                            </div>
                          </th>

                          <th
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            className={clasterLabels.length === index + 1 ? 'spacer last' : 'spacer'}
                            style={{ border: 'unset' }}
                          />
                        </Fragment>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {OPTIONS &&
                    Object.keys(grupedData).map((question, index) => {
                      const rows = grupedData[question];
                      return rows.map((row, rowIndex) => {
                        return (
                          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                          <tr key={index + '-' + rowIndex}>
                            {rowIndex === 0 && (
                              <td rowSpan={rows.length} className="question" style={{ verticalAlign: 'middle' }}>
                                {question}
                              </td>
                            )}
                            <td className="response">{row.response}</td>

                            {[
                              ...row.clusters,
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                              ...new Array(Math.max(0, clasterLabels.length - row.clusters.length)).fill(0),
                            ].map((value: { default: number; percentage: number }, idx) => {
                              return (
                                <Fragment key={idx}>
                                  <Tooltip
                                    mouseEnterDelay={0.5}
                                    title={
                                      <>
                                        <div>Question: {row.question}</div>
                                        <div>Response: {row.response}</div>
                                        <div>Cluster: Cluster {idx + 1}</div>
                                        <div>
                                          Value:
                                          {valueState !== 'default'
                                            ? (value[valueState] * 100).toFixed() + '%'
                                            : value[valueState]}
                                        </div>
                                      </>
                                    }
                                  >
                                    <td
                                      key={idx}
                                      style={{
                                        background: colors ? lightColor(colors[idx]) : '#fff',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {valueState !== 'default'
                                        ? (value[valueState] * 100).toFixed() + '%'
                                        : value[valueState]}
                                    </td>
                                  </Tooltip>
                                  <th
                                    className={row.clusters.length === idx + 1 ? 'spacer last' : 'spacer'}
                                    style={{ borderRight: 'unset', borderBottom: '1px solid #e0e0e0' }}
                                  />
                                </Fragment>
                              );
                            })}
                          </tr>
                        );
                      });
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Table;

const lightColor = (hex: string): string => {
  const color = Color(hex);
  return color.lightness(95).hex();
};
