import { FC, useState } from 'react';
import { Popover } from '../Popover';

interface Props {
  operator: string;
  color: string;
  zIndex: number;
  maxZIndex: number;
  onChange: (value: number) => void;
}

export const OperatorLabel: FC<Props> = ({ operator, color, zIndex, maxZIndex, onChange }) => {
  const handleClick = (value: number) => {
    onChange(value);
    setOpen(false);
  };

  const [open, setOpen] = useState<boolean>(false);

  const or = <span className="operator">{operator}</span>;

  const and = (
    <Popover
      placement="top"
      isOpen={open}
      openClose={setOpen}
      render={() => (
        <div className="zindex-popover" style={{ border: `1px solid ${color}` }}>
          <span style={{ color }}>Z-INDEX =&nbsp;</span>
          {[...Array(maxZIndex + 1).keys()].map((n: number) => (
            <div
              className="zindex-selection"
              style={{ backgroundColor: color }}
              onClick={() => {
                handleClick(n);
              }}
            >
              {n == 0 ? 'OFF' : n}
            </div>
          ))}
        </div>
      )}
    >
      <span>
        <span className="operator" style={{ cursor: 'pointer' }}>
          {operator}
        </span>
        {zIndex == 0 || <span style={{ fontWeight: 'bold', color }}> (z={zIndex})</span>}
      </span>
    </Popover>
  );

  return operator === 'AND' ? and : or;
};
