import { FC, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HcMore from 'highcharts/highcharts-more';
import { SaveOutlined, CheckSquareOutlined, ExclamationCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Select, Radio, Input, Modal, Slider, Spin, Typography } from 'antd';
import ButtonIcon from '../../../Common/ButtonIcon';
import { GlobalState } from '../../../store/global';
import { useBrandData } from '../hooks/useBrandsData';
import { useParams } from 'react-router';
import { countryMetadatas } from 'hawaii';
import { getMdmDataThunk } from '../../../store/thunk/secmdm/getMdmData';
import _ from 'lodash';
import { setClientInputValue, setIsPitchClient, setSelectedClient } from '../../../store/secmdmSlice';

const { Option } = Select;

HcMore(Highcharts);

interface CountryMetadata {
  name: string;
  iso: string;
  id: number;
  population: number;
  datamartId: string;
  isGlobal: boolean;
  activable: boolean;
  havasId: string;
}
interface IDistinct {
  dataset: string;
  distinct_yougov_id_count: number;
}
interface Props {
  selectedCountry: CountryMetadata;
  yougovIDs: { [key: string]: string[] };
  toChartData: [string, number][];
  propensityName: string | undefined;
  propensityCategory: string | undefined;
  savedResults: [number, number][] | undefined;
  distinct: IDistinct[];
}

const TabsContent: FC<Props> = ({
  selectedCountry,
  yougovIDs,
  toChartData,
  propensityName,
  propensityCategory,
  savedResults,
  distinct,
}) => {
  const [agencyList, setAgencyList] = useState<{ value: string; label: string }[] | null>(null);
  const [clientList, setClientList] = useState<{ value: string; label: string }[] | null>(null);
  const [selectedAgency, setSelectedAgency] = useState<number | undefined>();
  const [isPitch, setIsPitch] = useState<boolean>(false);
  const { agencies, clients } = useSelector((state: GlobalState) => state.secmdmState);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [propensityToSaveName, setPropensityToSaveName] = useState<string>('');
  const [selectedYouGovIDs, setSelectedYouGovIDs] = useState<string[]>([]);
  const { savePropensityAMDM, errorClusterSave, setErrorClusterSave } = useBrandData(selectedCountry.id);
  const [rangeData, setRangeData] = useState<number[]>([0]);
  const [marketSize, setMarketSize] = useState<number>(0);
  const [selectedRange, setSelectedRange] = useState<[number, number]>([0, 100]);
  const appIsLoading = useSelector((state: GlobalState) => state.appIsLoadingState);
  const [selectorInputValue, setSelectorInputValue] = useState<string>('');
  const [agencyError, setAgencyError] = useState(false);

  const { brandID } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!distinct) return;
    if (selectedYouGovIDs?.length === 0) {
      setMarketSize(0);
      return;
    }
    const [pm_predictions, pm_base_profile, pm_base_brandindex] = distinct;

    const marketCalc =
      (selectedYouGovIDs?.length * selectedCountry.population) / pm_base_brandindex.distinct_yougov_id_count;

    setMarketSize(Number(marketCalc.toFixed()));
  }, [selectedYouGovIDs]);

  useEffect(() => {
    if (!toChartData) return;
    setRangeData(Object.keys(toChartData).map((num) => Number((Number(num) / 100).toFixed(2))));
  }, [toChartData]);

  useEffect(() => {
    if (!agencies || !clients) return;
    setAgencyList(
      agencies.list
        .map((agency) => ({
          value: agency.id.toString(),
          label: agency.name,
        }))
        .sort((a, b) => a?.label.localeCompare(b?.label)),
    );
    setClientList(
      clients.list
        .map((client) => ({
          value: client.id.toString(),
          label: client.name === null ? '- No name' : client.name,
        }))
        .sort((a, b) => a?.label.localeCompare(b?.label)),
    );
  }, [agencies, clients]);

  const options = {
    chart: {
      plotBackgroundColor: 'rgb(102, 102, 102, 0.1)',
      type: 'column',
      style: {
        fontFamily: 'Baikal Regular, sans-serif',
      },
    },
    title: {
      text: 'Propensity Model results',
      style: {
        fontSize: '16px',
      },
      x: 0,
      align: 'left',
    },
    xAxis: {
      type: 'category',
      title: {
        text: '% of Propensity',
      },
      labels: {
        formatter: function (this: any) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const value = Number(this.value) * 100;
          return value.toFixed().toString() + '%';
        },
        style: {
          fontSize: '11px',
        },
        step: 5,
        rotation: -55,
      },
      tickInterval: 1,
    },
    yAxis: {
      title: {
        text: 'Respondents',
      },
    },
    plotOptions: {
      column: {
        colors: ['#00DCB9', '#785AFA', '#328CF5', '#F055FA', '#FFDC32', '#9BF5FC'],
      },
      series: {
        borderColor: '#F3F4F6',
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: 'Respondents',
        data: toChartData || [],
        color: '#00DCB9',
      },
    ],
  };

  useEffect(() => {
    if (!selectedRange) return;
    setErrorClusterSave(null);
    const [start, end] = selectedRange;
    const seletedIDs = [];
    for (let i = start; i <= end; i++) {
      const fixedNum = (i / 100).toFixed(2);
      if (yougovIDs[fixedNum]) {
        seletedIDs.push(...yougovIDs[fixedNum]);
      }
    }
    setSelectedYouGovIDs(seletedIDs);
    return () => setSelectedYouGovIDs([]);
  }, [selectedRange, brandID]);

  const setPropensityModelName = (value: string) => setPropensityToSaveName(value);

  const handleSavePropensity = async () => {
    if (!selectedAgency) {
      setAgencyError(true);
      return;
    } else {
      setAgencyError(false);
    }

    const metaEntity = {
      propensityToSaveName,
      selectedAgency: selectedAgency,
      selectedClient: clients.selected || clients.clientInputValue || -1,
      youGovIDs: selectedYouGovIDs,
      brandID,
      selectedRange,
      marketSize,
    };

    await savePropensityAMDM(metaEntity);
    setPropensityToSaveName('');
    setSelectedAgency(undefined);
    setSelectedClient(undefined);
    dispatch(setSelectedClient(undefined));
    dispatch(setClientInputValue(''));
    setSelectorInputValue('');
    setModalVisible(false);
  };
  const handleCloseModal = () => {
    setModalVisible(false);
    dispatch(setSelectedClient(undefined));
    dispatch(setClientInputValue(''));
    setSelectorInputValue('');
    setPropensityToSaveName('');
  };

  const isRangeSaved = (selectedRange: number[]) =>
    savedResults?.some(
      (subArr) => subArr.length === selectedRange.length && subArr.every((val, index) => val === selectedRange[index]),
    );

  const countries = useSelector((state: GlobalState) => state.secmdmState).countries.list;
  const validCountries = useMemo(
    () => countries.filter(({ iso }) => countryMetadatas.find((country) => country.iso === iso)),
    [countries],
  );

  const countrySelected = useSelector((state: GlobalState) => state.secmdmState.countries.selected);
  useEffect(() => {
    if (modalVisible && selectedCountry) {
      const countriesId = [];
      const countryId = validCountries.find(({ iso }) => iso === countrySelected?.iso)?.id;
      if (countryId) {
        countriesId.push(countryId);
      }
      dispatch(getMdmDataThunk(countriesId));
    }
  }, [modalVisible]);

  const clientSelectorBlur = () => {
    const selector = document.getElementById('ClientSelector');
    if (selector) selector.blur();
  };

  const clientSelector = () => {
    const selector = () => {
      return (
        <>
          <Select
            id="ClientSelector"
            value={clients.clientInputValue || clients.selected}
            showSearch
            placeholder={clients.isPitchClient ? 'Select your pitch client from the list' : 'Select your client'}
            filterOption={(inputValue, option) => {
              if (!option || typeof option.children !== 'string') {
                return false;
              }
              return (option.children as string).toLowerCase().includes(inputValue.toLowerCase());
            }}
            allowClear
            onClear={() => {
              dispatch(setSelectedClient(undefined));
              dispatch(setClientInputValue(''));
            }}
            showArrow
            style={{ width: '100%' }}
            onSelect={(client, opt) => {
              dispatch(setSelectedClient(Number(opt.key as number)));
              dispatch(setClientInputValue(''));
            }}
            onSearch={(event) => {
              setSelectorInputValue(event);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                dispatch(setClientInputValue(selectorInputValue));
                clientSelectorBlur();
              }
            }}
            dropdownRender={(menu) => (
              <div>
                {menu}
                {clients.isPitchClient && (
                  <>
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                      style={{ padding: '4px 8px', cursor: 'pointer', color: '#373737' }}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        dispatch(setClientInputValue(selectorInputValue));
                        clientSelectorBlur();
                      }}
                    >
                      <PlusSquareOutlined /> Add New Pitch client
                    </div>
                  </>
                )}
              </div>
            )}
          >
            {clients.list
              .filter((c) => !c.isPitch)
              .map((client) => (
                <Option key={client.id} value={client.id}>
                  {client.name}
                </Option>
              ))}
          </Select>
        </>
      );
    };

    return (
      <>
        <div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: '8px' }}>
            <span style={{ width: '10%', textAlign: 'left', marginRight: '20px' }}>Client:</span>
            <span style={{ marginRight: '8px' }}>Is it a Pitch -</span>
            <Radio.Group
              onChange={(e) => {
                dispatch(setIsPitchClient(e.target.value as boolean));
                dispatch(setClientInputValue(''));
                dispatch(setSelectedClient(undefined));
                setIsPitch(e.target.value as boolean);
              }}
              value={clients.isPitchClient}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>{selector()}</div>
        </div>
      </>
    );
  };

  return (
    <>
      {modalVisible && agencyList && clientList && (
        <Modal
          title="Save the Audience in AMDM to use it in Converged."
          open={true}
          onCancel={handleCloseModal}
          onOk={() => handleSavePropensity()}
          okText={'Save'}
          centered={true}
          okButtonProps={{ disabled: appIsLoading || !propensityToSaveName }}
        >
          <Spin
            spinning={appIsLoading}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              minHeight: '320px',
            }}
            tip="Loading..."
          >
            <h3>Audience Name</h3>
            <Input
              disabled={appIsLoading}
              placeholder="Audience Name"
              defaultValue={propensityToSaveName}
              onChange={(e) => setPropensityModelName(e.target.value)}
            />
            <Divider />
            <h3>Select agency and client.</h3>
            <h4>Agency:</h4>
            {
              <div style={{ height: '22px', color: 'red', marginLeft: '30%', marginTop: '12px' }}>
                {agencyError ? 'Please Select an agency' : ''}
              </div>
            }
            <Select
              style={{ width: '100%' }}
              showSearch
              disabled={appIsLoading}
              placeholder="Select a agency"
              optionFilterProp="children"
              onChange={(value: string) => setSelectedAgency(Number(value))}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={agencyList}
            />
            <Divider />
            {clientSelector()}
          </Spin>
        </Modal>
      )}
      {agencyList && clientList && (
        <div>
          <div className="ant-tabs-content__save">
            <ButtonIcon
              className="btn--sm"
              onClick={() => setModalVisible(true)}
              disabled={appIsLoading}
              label="Save"
              icon={<SaveOutlined />}
            ></ButtonIcon>
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={{ style: { width: '100%', height: '90%' } }}
          />
          <div
            style={{
              width: `calc(100% - 80px)`,
              marginLeft: 'auto',
              display: 'block',
              marginRight: '10px',
            }}
          >
            <Slider
              range
              onChange={(value) => setSelectedRange(value)}
              tooltip={{
                formatter: (value) => {
                  const index = Number(value?.toFixed()) || 0;
                  const num = rangeData[index] * 100;
                  if (!num) {
                    return index.toString() + '%';
                  } else {
                    return num.toFixed().toString() + '%';
                  }
                },
              }}
              defaultValue={[0, 100]}
              disabled={false}
            />
          </div>

          <Row gutter={[16, 8]} style={{ padding: '0px 40px' }}>
            <Divider />
            <Col span={24}>
              <h3 style={{ margin: '0px 0px', color: '#656565' }}>
                Choose your propensity threshold and save your audience.
              </h3>
            </Col>
            <Col span={12}>
              <span style={{ fontWeight: 'bold' }}>Brand:</span> <br />
              {propensityName}
            </Col>
            <Col span={12}>
              <span style={{ fontWeight: 'bold' }}>Category:</span> <br />
              {propensityCategory}
            </Col>
            <Col span={12}>
              <span style={{ fontWeight: 'bold' }}>Probability range between:</span> <br />
              {`${selectedRange[0]}% to ${selectedRange[1]}%`}
            </Col>
            <Col span={12}>
              <span style={{ fontWeight: 'bold' }}> Market size:</span> <br />
              {marketSize.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '0'}
            </Col>
            {isRangeSaved(selectedRange) && !errorClusterSave ? (
              <Col span={12}>
                <span style={{ fontWeight: 'bold' }}> Status:</span>
                <CheckSquareOutlined style={{ paddingLeft: '5px', fontSize: '16px', color: 'rgb(40 221 11)' }} />
                <span style={{ paddingLeft: '5px', fontSize: '16px', color: 'rgb(40 221 11)' }}>AMDM</span>
              </Col>
            ) : errorClusterSave ? (
              <Col span={12}>
                <span style={{ fontWeight: 'bold' }}> Status:</span>
                <ExclamationCircleOutlined style={{ paddingLeft: '5px', fontSize: '16px', color: 'rgb(241 51 51)' }} />
                <span style={{ paddingLeft: '5px', fontSize: '16px', color: 'rgb(241 51 51)' }}>AMDM FAILED</span>
              </Col>
            ) : (
              <Col span={12}></Col>
            )}

            <Col span={12}>
              <span style={{ fontWeight: 'bold' }}> Respondents:</span> <br />
              {selectedYouGovIDs?.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '0'}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default TabsContent;
